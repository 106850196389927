/* --------------------- */

// INDEX
	// 1. Slider
	// 2. Formulas
	// 3. Map
	// 4. Contact form

/* --------------------- */

/* ------------------ */
	// 1. Slider 
/* ------------------ */

	$('#product-features .slider').slick({
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		mobileFirst: true,
		responsive: [
			{
				breakpoint: 750,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 992,
				settings: 'unslick'
			}
		],
	});
	$('#planet-proper .slider').slick({
		arrows: false,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 750,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			},
		]
	});

	$('#content-slider').slick({
		arrows: false,
		centerMode: true,
		infinite: true,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1100,
				settings: {
					asNavFor: $('#subnav'),
					adaptiveHeight: false,
				}
			},
		    {
				breakpoint: 575,
				settings: {
					centerMode: false
				}
		    },
		]
	});

	$('#subnav').slick({
		arrows: false,
		slidesToShow: 5,
		slidesToScroll: 5,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					asNavFor: $('#content-slider'),
					centerMode: true,
					infinite: true,
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			},
		    {
		      breakpoint: 575,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1,
		      }
		    },
		]
	});

	$('#subnav li').click(function(e){
		$('#subnav li').removeClass('active');
		$(this).addClass('active');
		var index = $(this).index();
		$('#content-slider').slick('slickGoTo',index);
	});

	$('#content-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		$('#subnav li').removeClass('active');
		$('#subnav li:eq(' + nextSlide + ')').addClass('active');
	});

	$('.slick-prev').click(function(){
		$(this).siblings('.slider').slick('prev');
	});

	$('.slick-next').click(function(){
		$(this).siblings('.slider').slick('next');
	});

/* ------------------ */
	// 2. Formulas 
/* ------------------ */

	var initialLiters = 3785;
	var currentUnit = 'liters';

	$('.bath-top input').change(function(){
		var newValue = $(this).val();
		if( currentUnit == 'liters' ) {
			$(this).attr('data-liters',newValue);
		} else {
			var gallonsToLiters = 3.78541 * newValue;
			$(this).attr('data-liters',gallonsToLiters);
		}
		$('.bath-list .data').each(function(){
			var val = $(this).data('initial');
			var result = val * $('.bath-top input').attr('data-liters')/initialLiters;
			$(this).text( Math.round(result * 100) / 100 );
		});
	});

	$('.bath-top .nav-item a').click(function(){
		var value = $(this).text();
		currentUnit = value.toLowerCase();
		$('.bath-top h3 span').text( value );
		if( currentUnit == 'liters' ){
			var convertedValue = (+$('.bath-top input').val() * 3.78541);
		} else {
			var convertedValue = (+$('.bath-top input').val() / 3.78541);
		}
		$('.bath-top input').val( convertedValue );
	});

/* ------------------ */
	// 3. Map
/* ------------------ */

	function initMap() {
		var position = {lat: 20.5617475, lng: -100.2708513};
		var map = new google.maps.Map(document.getElementById('map'), {
			center: position,
			zoom: 17,
			disableDefaultUI: true,
			styles: [{"featureType": "water","elementType": "geometry","stylers": [{"color": "#e9e9e9"},{"lightness": 17}]},{"featureType": "landscape","elementType": "geometry","stylers": [{"color": "#f5f5f5"},{"lightness": 20}]},{"featureType": "road.highway","elementType": "geometry.fill","stylers": [{"color": "#ffffff"},{"lightness": 17}]},{"featureType": "road.highway","elementType": "geometry.stroke","stylers": [{"color": "#ffffff"},{"lightness": 29},{"weight": 0.2}]},{"featureType": "road.arterial","elementType": "geometry","stylers": [{"color": "#ffffff"},{"lightness": 18}]},{"featureType": "road.local","elementType": "geometry","stylers": [{"color": "#ffffff"},{"lightness": 16}]},{"featureType": "poi","elementType": "geometry","stylers": [{"color": "#f5f5f5"},{"lightness": 21}]},{"featureType": "poi.park","elementType": "geometry","stylers": [{"color": "#dedede"},{"lightness": 21}]},{"elementType": "labels.text.stroke","stylers": [{"visibility": "on"},{"color": "#ffffff"},{"lightness": 16}]},{"elementType": "labels.text.fill","stylers": [{"saturation": 36},{"color": "#333333"},{"lightness": 40}]},{"elementType": "labels.icon","stylers": [{"visibility": "off"}]},{"featureType": "transit","elementType": "geometry","stylers": [{"color": "#f2f2f2"},{"lightness": 19}]},{"featureType": "administrative","elementType": "geometry.fill","stylers": [{"color": "#fefefe"},{"lightness": 20}]},{"featureType": "administrative","elementType": "geometry.stroke","stylers": [{"color": "#fefefe"},{"lightness": 17},{"weight": 1.2}]}]
		});
		var icon = {
			scaledSize: new google.maps.Size(35, 51),
			origin: new google.maps.Point(0, 0),
          	anchor: new google.maps.Point(18, 51),
			url: '/assets/img/map_marker.png',
		}
		var marker = new google.maps.Marker({
			position: position,
			map: map,
			icon: icon,
		});
	}

/* ----------------------- */
	// 4. Contact form
/* ----------------------- */

	function revealMessage(msg,form){
		form.find('.form-message').html(msg).addClass('open');
        setTimeout(function() {
        	form.find('.form-message').html('').removeClass('open');
        }, 2000);
	}

	$('.contact-form').submit(function(e) {
		// Prevent the form from actually submitting
		e.preventDefault();
		var form = $(this);

		// Get the post data
		var data = $(this).serialize();

		// block multiple clicks
		form.find('button').attr('disabled',true);

		console.log('hi');

		// Send it to the server
		$.post('/', data, function(response) {
			console.log(response);
	        if (response.success) {
	        	ga('send', {'hitType': 'pageview', 'page': '/formulario-enviado', 'title': 'Formulario enviado'});
	            revealMessage('Gracias por contactarnos, te contactaremos cuanto antes.',form);
	        } else {
	            revealMessage('Hubo un error, intenta de nuevo más tarde.',form);
	        }
	        form.find('button').attr('disabled',false);
	    });
	});